import React from 'react'
import Layout from '../components/layout'

import { Link } from 'theme-ui'
import { Image, Box, Flex } from 'rebass'
import SEO from '../components/seo'
import {
  TextBase as Text,
  HeadingBase as Heading,
  ContainerFull,
} from '../components/blocks'

import { Spacer, Container, ButtonBase } from '../components/atoms'
import CTABlock from '../components/contact'
import styled from '@emotion/styled'

const headerImage = require('../images/group_76.svg')
const designImage = require('../images/design.svg')
const strategyImage = require('../images/strategy.svg')
const trainingImage = require('../images/training.svg')
const developmentImage = require('../images/development.svg')
const caseImg = require('../images/demo_1.svg')

const Section = styled(Box)`
  // border-bottom: solid 1px #eee;
`

export const LinkArrow = styled(ButtonBase)`
  position: absolute;
  bottom: 0;
  &::after {
    position: absolute;
    content: '\00bb';
    transform: rotateZ(-270deg);
    margin-left: 10px;
  }
`

const SolutionItem = styled(Box)`
  border-bottom: solid 1px #eee;
  display: block;
  padding-left: 0;
  padding-bottom: 8px;
  width: 33%;
  margin-right: 16px;
  margin-bottom: 8px;
`

const ServicesList = [
  {
    title: 'Branding',
    subhead: 'Design Research',
    image: designImage,
    desc:
      'Conducting Design Research allows our team to validate that the problem we’re solving for is worth the time and money to solve for it. It develops a fuller understanding of the problem for the team from the eyes of the people using the product. Having that validation and team understanding sets the stage for the team to find innovative solutions to the problem.',
    items: [
      'Analyze cliente business',
      'Branding Strategy',
      'Research opportunities',
      'Create information architecture',
      'Study the target audience',
    ],
  },
  {
    title: 'Create engaging user experiences',
    image: strategyImage,
    desc:
      'From strategy to implementation, we collaborate with you to design experiences that build successful products and businesses. We pay close attention to flows and not individual screens because we know that is what builds a great user experience. Each of our team members has a design mindset; all keeping your users top of mind. ',
    subhead: 'UX, UI, & PRODUCT DESIGN',
    items: [
      'Interaction Design',
      'UX Research',
      'Design Systems',
      'Visual Design',
    ],
  },
  {
    title: 'Front-end Development',
    image: trainingImage,
    desc:
      'Conducting Design Research allows our team to validate that the problem we’re solving for is worth the time and money to solve for it. It develops a fuller understanding of the problem for the team from the eyes of the people using the product. Having that validation and team understanding sets the stage for the team to find innovative solutions to the problem.',
    subhead: 'Design Research',
    items: ['Reactjs', 'Vuejs', 'Svelte', 'Angular'],
  },
  {
    title: 'Build for the mobile world',
    subhead: 'Mobile App Development',
    image: trainingImage,
    desc:
      'Conducting Design Research allows our team to validate that the problem we’re solving for is worth the time and money to solve for it. It develops a fuller understanding of the problem for the team from the eyes of the people using the product. Having that validation and team understanding sets the stage for the team to find innovative solutions to the problem.',
    items: ['React Native', 'Kotlin', 'Swift', 'Flutter'],
  },
  {
    title: 'Build Production Ready application quickly',
    image: trainingImage,
    subhead: 'Product Development',
    desc:
      'Conducting Design Research allows our team to validate that the problem we’re solving for is worth the time and money to solve for it. It develops a fuller understanding of the problem for the team from the eyes of the people using the product. Having that validation and team understanding sets the stage for the team to find innovative solutions to the problem.',
    items: ['Elixir', 'Go Lang', 'Ruby', 'Node.js'],
  },
]

const ServicesPage = () => (
  <Layout>
    <SEO
      title={'Services '}
      description="Services we provide through our front-end engineers and design consultants"
    />
    <Container pl={[0, 0, 4]}>
      <Spacer>
        <Box ml={-4}>
          <Text variant="text.pageTitle">Services</Text>
          <Text variant="text.pageDesc">We create digital services</Text>
        </Box>
      </Spacer>

      {/* <Box width={1}>
        <Flex width={[1, 1, 3 / 12]} mx={3}>
          <Image width="90px" src={strategyImage} />
          <Box ml={3}>
            <Heading bold mt={3}>
              Strategy
            </Heading>
            <Text mb={5}>
              Game plans and outlines for every step of the way.
            </Text>
          </Box>
        </Flex>
        <Flex width={[1, 1, 3 / 12]} mx={3}>
          <Image width="90px" src={designImage} />
          <Box ml={3}>
            <Heading bold mt={3}>
              Strategy
            </Heading>
            <Text mb={5}>
              Game plans and outlines for every step of the way.
            </Text>
          </Box>
        </Flex>
        <Flex width={[1, 1, 3 / 12]} mx={3}>
          <Image width="90px" src={trainingImage} />
          <Box ml={3}>
            <Heading bold mt={3}>
              Strategy
            </Heading>
            <Text mb={5}>
              Game plans and outlines for every step of the way.
            </Text>
          </Box>
        </Flex>
        <Flex width={[1, 1, 3 / 12]} mx={3}>
          <Image width="90px" src={developmentImage} />
          <Box ml={3}>
            <Heading bold mt={3}>
              Strategy
            </Heading>
            <Text mb={5}>
              Game plans and outlines for every step of the way.
            </Text>
          </Box>
        </Flex>
        <Flex width={[1, 1, 3 / 12]} mx={3}>
          <Image width="90px" src={strategyImage} />
          <Box ml={3}>
            <Heading bold mt={3}>
              Strategy
            </Heading>
            <Text mb={5}>
              Game plans and outlines for every step of the way.
            </Text>
          </Box>
        </Flex>
      </Box> */}
    </Container>
    <Flex flexDirection="column" mt={5}>
      {ServicesList.map(({ title, desc, subhead, image, items }) => (
        <ContainerFull variant="styles.section" bgp="trans.0" mt={3}>
          <Flex >
            <Box width={[1, 1, 4/12]} pl={6} pt={6} mt={0} pr={6}>
              <Image width="100%" src={image} />
            </Box>
            <Section width={[1, 1, 6/ 12]} pt={6} pb={5} variant="styles.sectionBlock">
              <Box pb={4}>
                <Text variant="text.shortTitle">{subhead}</Text>
                <Heading variant="text.sectionTitle">{title}</Heading>
                <Text variant="text.sectionBody">{desc}</Text>
              </Box>

              <Text variant="text.shortTitle">What we do</Text>
              <Flex as="ul" flexWrap="wrap" mt={4} mb={3}>
                {items.map((s) => (
                  <SolutionItem as="li">
                    <Text>{s}</Text>
                  </SolutionItem>
                ))}
              </Flex>

              <Box mt={2}>
                <Text variant="text.boldLink">Read about {subhead}</Text>
              </Box>
            </Section>
          </Flex>
        </ContainerFull>
      ))}
    </Flex>
    <CTABlock/>
  </Layout>
)

export default ServicesPage
